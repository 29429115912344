var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contract" }, [
    _c(
      "div",
      { staticClass: "title" },
      [_c("app-header", { attrs: { title: _vm.title, isShowBack: true } })],
      1
    ),
    _c("div", { staticClass: "contract_rent" }, [
      _c("div", { staticClass: "contract_title" }, [
        _vm._v("《威富承租租赁合同》")
      ]),
      _c("span", { staticClass: "contract_text" }, [
        _vm._v(
          "深圳市房屋租赁合同书、房屋租赁登记备案须知、市房屋租赁安全管理责任书..."
        )
      ]),
      _c(
        "span",
        {
          staticClass: "contract_load",
          on: {
            click: function($event) {
              _vm.contractLoad("1")
            }
          }
        },
        [_vm._v("立即下载")]
      ),
      _vm._m(0)
    ]),
    _c("div", { staticClass: "contract_protocol" }, [
      _c("div", { staticClass: "contract_title" }, [
        _vm._v("《智慧公寓合作协议》")
      ]),
      _c("span", { staticClass: "contract_text" }, [
        _vm._v("房屋租赁合作协议，智慧公寓甲乙双方合作条款约定")
      ]),
      _c(
        "span",
        {
          staticClass: "contract_load",
          on: {
            click: function($event) {
              _vm.contractLoad("2")
            }
          }
        },
        [_vm._v("立即下载")]
      ),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contract_footer" }, [
      _c("span", { staticClass: "contract_versions" }, [_vm._v("版本：V2.0")]),
      _c("span", { staticClass: "contract_update" }, [
        _vm._v("更新日期：2020-01-06")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contract_footer" }, [
      _c("span", { staticClass: "contract_versions" }, [_vm._v("版本：V2.0")]),
      _c("span", { staticClass: "contract_update" }, [
        _vm._v("更新日期：2020-01-06")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }