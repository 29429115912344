<template>
  <div class="contract">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>

    <div class="contract_rent">
      <div class="contract_title">《威富承租租赁合同》</div>
      <span class="contract_text">深圳市房屋租赁合同书、房屋租赁登记备案须知、市房屋租赁安全管理责任书...</span>
      <span class="contract_load" @click="contractLoad('1')">立即下载</span>
      <div class="contract_footer">
        <span class="contract_versions">版本：V2.0</span>
        <span class="contract_update">更新日期：2020-01-06</span>
      </div>
    </div>

    <div class="contract_protocol">
      <div class="contract_title">《智慧公寓合作协议》</div>
      <span class="contract_text">房屋租赁合作协议，智慧公寓甲乙双方合作条款约定</span>
      <span class="contract_load" @click="contractLoad('2')">立即下载</span>
      <div class="contract_footer">
        <span class="contract_versions">版本：V2.0</span>
        <span class="contract_update">更新日期：2020-01-06</span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    let _this = this;
    return {
      title: "合同下载",
      msg: ""
    };
  },
  methods: {
    contractLoad(type) {
      let url1 =
        "http://img.wavewisdom.com/group1/M00/05/14/rBI4mV4eZO6AcHUvAATqAOOeF_I001.doc";
      let url2 =
        "http://img.wavewisdom.com/group1/M00/05/14/rBI4mV4eZTiADLooAACeAEPyzo4566.doc";
      axios({
        method: "get",
        url: type == 1 ? url1 : url2,
        responseType: "blob"
      })
        .then(response => {
          var elink = document.createElement("a");
          elink.download =
            type == 1 ? "威富承租租赁合同.doc" : "智慧公寓合作协议.doc";
          elink.style.display = "none";
          var blob = new Blob([response.data]);
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        })
        .catch(error => {
          window.open(type == 1 ? url1 : url2);
        });
    }
  }
};
</script>
<style lang="scss">
.title {
  position: relative;
  top: 20px;
  left: 20px;
}

.contract_rent {
  position: relative;
  margin-top: 30px;
  left: 10%;
  height: 250px;
  width: 65%;
  border: 1px solid #eeeeee;
}

.contract_protocol {
  position: relative;
  top: 50px;
  border: 1px solid #eeeeee;
  left: 10%;
  height: 250px;
  width: 65%;
}

.contract_title {
  font-weight: bolder;
  position: absolute;
  top: 32px;
  left: 30px;
}

.contract_text {
  position: absolute;
  top: 103px;
  left: 41px;
  color: #999;
}

.contract_load {
  color: #2f72f7;
  position: absolute;
  top: 103px;
  right: 115px;
  cursor: pointer;
}

.contract_load:hover {
  color: blue;
}

.contract_footer {
  color: #999;
  position: absolute;
  bottom: 22px;
  right: 112px;
  font-size: 14px;
}

.contract_update {
  margin-left: 30px;
}
</style>